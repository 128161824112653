@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Croissant+One&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  scroll-behavior: smooth;
  background-color: #232323;
}

.font-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.font-sec {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.b-1 {background-color: #202020;}
.b-2 {background-color: #232323;}
.b-3 {background-color: #2c2c2c;}
.p-1 {background-color: #00BCFF;}
.p-2 {background-color: #00A6E1;}

.t-p-1 {color: #00A6E1;}
.t-p-2 {color: #00A6E1;}

.bg-linear-main {
  background: linear-gradient(97deg, #232323 42.42%, rgba(35, 35, 35, 0.00) 95.89%);
}

.bg-main {
  background-image: url("./assets/bg.jfif");
  background-size:cover;
  background-position: left;
}

.t-64 {
  font-size: 64px;
}

@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

#container {
  filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
  height: 64px;
    position: absolute;
    width: 600px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 32px;

    color: white;

    text-align: left;
    
    user-select: none;
}

.h-364 {
  height: 363px;
}

.t-36 {
  font-size: 36px;
}

.t-26 {
  font-size: 26px;
}

.nav-btn {
  -webkit-transition-duration: 0.2s;
  border-bottom: none;
}

.nav-btn:hover {
  -webkit-transition-duration: 0.2s;
  border-bottom: #00BCFF 1px solid;
}

.nav-btn h1 {
  -webkit-transition-duration: 0.2s;
  color: white;
}

.nav-btn-social {
  -webkit-transition-duration: 0.2s;
  background-color: transparent;
}

.nav-btn-social:hover {
  -webkit-transition-duration: 0.2s;
  background-color: #00A6E1;
}

.nav-btn:hover h1 {
  -webkit-transition-duration: 0.2s;
  color: #00BCFF;
}

.b-color {
  border-color: #00A6E1;
}

.down {
  transform: translateY(24px);
}

::-webkit-scrollbar {
  width: 10px;
  padding: 25px;
}

::-webkit-scrollbar-track {
  background-color: #232323;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #575757;
  border-radius: 20px;
  border: rgb(46, 46, 46) 1px solid;
  width: 8px;
}

@media only screen and (max-width: 1200px) {
  .deco-I {
    width: 270px;
  }
  .container-w-adjust {
    width: 100%;
    padding-top: 24px;
  }
  .text-mobile-view-container-place {
    position: absolute;
    top: 84px;
  }
  .p-mobile {
    margin-top: 48px;
  }
  .mobile-less-padding {
    padding: 12px;
  }
}

@media only screen and (max-width: 900px) {
  .show-on-mobile {
    opacity: 1;
  }
  .bg-main {
    background-image: url("./assets/small-bg.png");
    width: 130%;
    background-size: cover;
  }
  .t-64 {
    font-size: 32px;
    padding-bottom: 6px;
  }
  .t-32 {
    font-size: 16px;
  }
  .t-16 {
    font-size: 8px;
  }
  .t-26 {
    font-size: 16px;
  }
  #text2 {
    font-size: 16px;
    height: 16px;
  }
  #text1 {
    height: 16px;
    font-size: 16px;
  }
  #filters {
    height: 28px;
  }
  #div-content-I {
    justify-content: center;
    padding-left: 0px;
  }
  .navbar-container-siim {
    display: none;
  }
  .deco-I {
    margin-top: -140px;
    width: 150px;
    height: 225px;
  }
  .container-w-adjust {
    width: 100%;
  }
  #container-II {
    padding: 46px;
    padding-top: 56px;
  }
  .container-I {
    padding: 56px;
  }
  #SEP-RECT {
    width: 265px;
    height: 8px;
  }
  #title-ettevõtte {
    font-size: 24px;
  }
  #title-teenused {
    font-size: 24px;
  }
  .container-I > p {
    font-size: 16px;
    width: 100%;
    padding-bottom: 48px;
  }
  .txt-p-t {
    font-size: 16px;
    width: 100%;
    padding-top: 18px;
  }
  .sep-adjuster {
    background: linear-gradient(90deg, rgba(0,166,225,1) 0%, rgba(0,188,255,1) 100%);
    width: 265px;
    height: 8px;
  }
  .sep-adjuster svg {
    display: none;
  }
  #container-III {
    padding-left: 16px;
    padding-right: 16px;
  }
  .mobile-flex-none {
    flex-direction: column;
  }
  .contact-container {
    width: 100%;
  }
  .mobile-less-padding {
    padding: 0px;
  }
  #container-IV {
    padding: 64px;
  }
  #title-projektimüük {
    font-size: 24px;
  }
  .container-IV > p {
    font-size: 14px;
    width: 100%;
  }
  #container {
    display: none;
  }
  .text-mobile-view-container-place {
    position: absolute;
    top: 84px;
  }
  .p-mobile {
    margin-top: 48px;
  }
  .mobile-footer-h {
    text-align: center;
  }
  .I-t-- {
    text-align: center;
  }
  .I-f-- {
    justify-content: center;
  }
  #filters {
    display: none;
  }
  .navbar-logo {
    display: none;
  }
  .logo-in-container-mobile {
    opacity: 1;
    height: fit-content;
    margin-bottom: 32px;
  }
  .add-margin {
    margin-left: 36px;
    margin-right: 36px;
  }
  .remove-padding {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
  }
  .remove-on-mobile {
    display: none;
  }
  .text-p-t {
    width: 100%;
  }
  #container-IV {
    padding: 56px;
  }
}

@media only screen and (max-width: 320px) {
  #SEP-RECT {
    width: 240px;
  }
  .sep-adjuster {
    width: 240px;
  }
  .text-mobile-view-container-place {
    font-size: 28px;
  }
  .p-mobile {
    font-size: 18px;
  }
  .banner-img-building {
    background-position: left;
    background-size: cover;
  }
}

.mobile-flex-none {
  display: flex;
}